
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { getComponent, getView, sleep } from '@/utils/helpers';
import workflowModule from '@/store/modules/workflowModule';
import gptModule from '@/store/modules/gptModule';
import GptMixin from './GptMixin';
import axiosClient from '@/lib/rest/axiosClient';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    TasqLoader: () => getComponent('loaders/TasqLoader'),
    ActionBuilderForm: () => getView('ActionBuilderForm'),
    FormFilterPopup: () => getView('FormFilterPopup'),
    ConfirmPopup: () => getComponent('tasqsCommon/ConfirmPopup'),
    TasqAiCommentEntry: () => getComponent('gpt/TasqAICommentEntry'),
    TasqAiAudioEntry: () => getComponent('gpt/TasqAIAudioEntry'),
  },
})
export default class GroupList  extends mixins(GptMixin) {

  @Prop({ type: Object, required: true, default: () => {} }) group?: any;

  @Prop({ type: Boolean, required: true, default: false }) accordianState?: any;

  @Prop({ type: Boolean, required: true, default: false })
  showDataEntryState?: any;

  @Prop({ type: Boolean, required: false, default: false })
  manualGroup?: any;

  @Prop({ type: String, required: true, default: 'In line View' })
  groupViewState?: any;

  @Prop({ type: String, required: true, default: false }) groupType?: any;

  searchQuery = '';

  downtimeReason = [
    'Accidental Shutdown',
    'Capacity CVX Facility',
    'Comms Issues',
    'Compressor Package Equipment Issues',
    'Controlled Valves - Failure / Repair',
    'Downhole Failure',
    'Economics',
    'Fixed Equipment - Repairs / Issues',
    'Frac Hit - 3rd Party',
    'Frac Hit - CVX Related',
    'Frac Hit Mitigation - 3rd Party',
    'Frac Hit Mitigation - CVX',
    'Gas Takeaway',
    'HES Incident',
    'I+E Failures / Repairs',
    'I+E Proactive Routine Tasks',
    'Long Term Shut In',
    'Low Reservoir Pressure / Loaded Up with Water',
    'Midstream Preventative Maintenance',
    'Oil Takeaway',
    'Process Upset',
    'Process Upset - Midstream',
    'Regulatory - Subsurface',
    'Regulatory / Licensing',
    'Reservoir Stimulation',
    'Rotating Equipment - Failures / Repairs',
    'SIMOPS / HES Proactive',
    'Solid Management - Surface Facilities',
    'Subsurface Chemical - Proactive',
    'Subsurface Chemical - Reactive',
    'Surface Chemical Treatment',
    'Surface Equipment Proactive Routine Tasks',
    'Surface Equipment Projects / Optimization',
    'Troubleshooting - Unknown Downhole Issues',
    'Water Takeaway',
    'Weather - Proactive',
    'Weather - Reactive',
    'Well Optimization / Diagnostics',
    'Wellhead Issues',
  ];


  get groupPreview(){
    return this.group.query === 'ai_reports' ? 'Tasq AI Reports' : this.group.query === 'audio_entry' ? 'Tasq AI Audio Entry' : this.group.query
  }

  jsonFields = {
    'Well name': 'wellName',
    Deferment: 'defermentValue',
    Route: 'route',
    Team: 'team',
    CycleTime: 'dateAgoText',
  };


  downtimePayload = {
    "body": "",
    'type': 'Downtime',
    "wellMetaData": {
        "operator_name": "",
        "operator_id": "",
        "nodeid": "",
        "level": "well"
    },
    "form": [
        {
            "artificialLiftTypes": [],
            "dateOfDisplay": {
                "id": 0,
                "title": "Created date"
            },
            "fields": [
                {
                    "category": "Date/Time",
                    "hideField": false,
                    "id": "1lvpfxraq",
                    "isCustomSource": true,
                    "isMultiselect": false,
                    "localVal": "2024-08-01:00:00:00",
                    "required": true,
                    "showDescription": false,
                    "showLatestResponseOnWell": false,
                    "source": "",
                    "sourceType": "Custom",
                    "title": "Downtime Start",
                    "type": "Date",
                    "useCurrentDate": true
                },
                {
                    "category": "Textbox",
                    "hideField": false,
                    "id": "1lvpg01yc",
                    "isCustomSource": true,
                    "isMultiselect": false,
                    "localVal": null,
                    "required": false,
                    "showDescription": false,
                    "showLatestResponseOnWell": false,
                    "source": "",
                    "sourceType": "Custom",
                    "title": "Downtime Hours",
                    "type": "Single",
                    "useCurrentDate": true
                },
                {
                    "category": "Textbox",
                    "hideField": false,
                    "id": "1lvpg0r4w",
                    "isCustomSource": true,
                    "isMultiselect": false,
                    "localVal": null,
                    "required": false,
                    "showDescription": false,
                    "showLatestResponseOnWell": false,
                    "source": "",
                    "sourceType": "Custom",
                    "title": "Downtime Volume",
                    "type": "Single",
                    "useCurrentDate": true
                }
            ],
            "category": "Production",
            "description": "",
            "id": "da239a5d-0891-bd5e-0a9e-b61d153edfb3",
            "title": "Downtime",
            "showLatestResponseOnWell": 0,
            "isAiForm": true,
            "downtime": true,
            "formTime": "2025-01-15T11:34:25.983Z"
        }
    ]
}

  selectedDowntimeReason = 'Automate Downtime Reason';

  get isSelectedWell() {
    return (
      gptModule.selectedAiGroupWellsObj.filter(
        (s) => s.typeOfData === this.group.query
      ).length === this.group.wells.length
    );
  }

  toggleCheckbox() {
    const isSelected = this.isSelectedWell;
    gptModule.updateGptAiGroupWellsByType(this.group.query);
    if (!isSelected) {
      const allData = this.group.wells.map((w) => {
        return { wellName: w.wellName, typeOfData: this.group.query };
      });
      gptModule.addGptAiGroupWellsByType(allData);
    }
  }

  changeGroupView(query: string, view: string) {
    this.$emit('change-group-view', query, view);
  }

  changeDowntimeReason(query: string, view: string) {
    this.selectedDowntimeReason = view;
    this.$emit('change-downtime-reason', query, view);
  }

  cancelDowntimeReason() {
    this.selectedDowntimeReason = 'Automate Downtime Reason';
  }

  async saveDowntimeReason() {
    console.log(this.group);
    const payload =  this.downtimePayload
    payload['body'] = this.selectedDowntimeReason
    const allPayloads = []

    if(this.group && this.group.wells){

      this.group.wells.forEach((well) => {
        let localPayload = JSON.parse(JSON.stringify(payload));
        // console.log( well.wellName);
        localPayload['wellMetaData']['nodeid'] = well.wellName
        localPayload['wellMetaData']['operator_name'] = this.operatorDetails['operatorName']
        localPayload['wellMetaData']['operator_id'] = `${this.operatorDetails['operatorID']}`
        // this.$emit('save-downtime-reason', payload);
        // console.log(localPayload['wellMetaData']);
        allPayloads.push(localPayload)
      });




      // console.log(allPayloads);

      const results = await Promise.all(
      [allPayloads.map(resource =>
        axiosClient.post('/ai/comments', resource)
      )]
    );

    this.selectedDowntimeReason = 'Automate Downtime Reason';
    this.$tasqAlert({
      title: 'Success',
      message: 'Downtime Entry Submitted',
      type: 'success',
    });
    await sleep(5500)
    this.getLastActions()
    await sleep(8500)
    this.getLastActions()
    }

    this.selectedDowntimeReason = 'Automate Downtime Reason';


  }

  async getLastActions() {
    try {

      let operator =  this.operatorDetails['operatorName']

      if( operator === 'demo'){
          operator = 'pdce'
      }

      const { data } = await axiosClient.get('/meta/latest-actions', {
        params: {
          operator: operator,
        },
      });
      gptModule.setLastestWellsLastActions(data);
    } catch (error) {}
  }

  get settingsOptions() {
    if (this.group && this.group.settings && this.group.settings.is_published) {
      return ['Delete'];
    }
    return ['Publish', 'Delete'];
  }

  get isGroupOwner() {
    return (
      this.group.created_by === workflowModule.user.email &&
      this.groupType !== 'audio_entry' &&
      !this.manualGroup
    );
  }

  sumByKey(array, key = 'defermentValue') {
    const sum = array.reduce((acc, obj) => {
      // Ensure the key exists in the object and is a number
      if (obj.hasOwnProperty(key) && typeof obj[key] === 'number') {
        acc += obj[key];
      }
      return acc;
    }, 0);
    return parseFloat(sum.toFixed(0)).toLocaleString();
  }

  hideAudioEntryUI = false;
  async refreshAudioEntryPopup() {
    this.hideAudioEntryUI = true;
    this.$nextTick(() => {
      this.hideAudioEntryUI = false;
    });
    await sleep(3500);
    await this.$emit('refresh-last-actions');
  }

  performAction(action: string) {
    console.log(action);
    console.log(this.group);
    this.$emit('perform-action', {
      action: action.toLowerCase(),
      group: this.group,
    });
  }
}
